<template>
  <div class="content">
    <bict-header/>
    <div class="page-notice-details page-wrap-1200 page-min-width">
      <div class="page-notice-details-left">
        <div class="page-notice-menu-item" v-for="(item, index) in articleList" :key="index"
             @click="handleItem(item.articleId)">
          <div class="page-notice-menu-item-time">
            <div>{{ item.createTime.substring(5, 10) }}</div>
            <div>{{ item.createTime.substring(0, 4) }}</div>
          </div>
          <div class="page-notice-menu-item-title" :title="item.summary ? item.summary : item.title">
            {{ item.summary ? item.summary : item.title }}
          </div>
        </div>
      </div>
      <div class="page-notice-details-right" v-loading="articleLoading">
        <div class="page-notice-details-main">
          <h3>{{ articleInfo.title }}</h3>
          <div class="page-notice-details-main-date">{{ $utils.parseTime(articleInfo.createTime) }}</div>
          <div class="page-notice-details-main-text" v-html="articleInfo.content"></div>
        </div>
      </div>
    </div>
    <bict-bottom/>
  </div>
</template>

<script>
import BictHeader from "../components/BictHeader";
import BictBottom from "../components/BictBottom";
import { ArticleDetail, ProcoinArticle } from "../api";

export default {
  name: 'NoticeDetails',
  components: {
    BictHeader,
    BictBottom
  },
  data () {
    return {
      articleList: [],
      articleInfo: {},
      articleLoading: true
    }
  },
  mounted () {
    this.procoinArticle()
    let articleId = this.$route.params.id
    // eslint-disable-next-line no-empty
    if (articleId==null||articleId===0){
      articleId = this.articleList[0].articleId;
    }
    this.articleDetail(articleId)
  },
  methods: {
    procoinArticle () {
      const params = {
        pageNo: 1,
        pageSize: 100000
      }
      params.lang = 'tw'
      ProcoinArticle(params).then(res => {
        if (res.code === '200') {
          const data = res.data.data
          data.forEach(item => {
            item.createTime = this.$utils.parseTime(item.createTime, '{y}-{m}-{d}')
          })
          this.articleList = data
          this.articleDetail(data[0].articleId);
        }
      })
    },
    handleItem (articleId) {
      this.articleLoading = true
      this.articleDetail(articleId)
    },
    articleDetail (articleId) {
      const params = {
        articleId: articleId,
        lang: 'tw'
      }
      ArticleDetail(params).then(res => {
        if (res.code === '200') {
          this.articleLoading = false
          this.articleInfo = Object.assign({}, res.data)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  width: 100%;
}

.page-notice-details {
  display: flex;
  margin: 70px auto auto;
  width: 1200px;

  .page-notice-details-left {
    width: 320px;
    min-height: calc(100vh - 200px);
    background: rgba(247, 251, 255, 1);
    padding: 30px 0;

    .page-notice-menu-item {
      display: flex;
      margin-bottom: 15px;
      padding: 10px 0;
      border-radius: 5px;
      cursor: pointer;


      &:hover {
        background: #2C72EF;
        color: #ffffff;

        .page-notice-menu-item-time {
          color: #BAD3FF;
        }
      }

      .page-notice-menu-item-time {
        color: #8192A5;
        font-size: 12px;
        border-right: solid 1px #D3D3D3;
        padding: 0 20px;
      }

      .page-notice-menu-item-title {
        flex: 1;
        overflow: hidden;
        padding: 0 20px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  .page-notice-details-right {
    flex: 1;
    padding: 30px 20px 30px 50px;

    .page-notice-details-main {
      h3 {
        color: #15223B;
        font-size: 21px;
        margin-bottom: 15px;
      }

      .page-notice-details-main-date {
        color: #8192A5;
        font-size: 12px;
        margin-bottom: 25px;
      }

      .page-notice-details-main-text {
        font-size: 15px;
        color: #15223B;
        line-height: 32px;
        padding-bottom: 40px;
        border-bottom: solid 1px #D3D3D3;
      }
    }

    .page-notice-details-other {
      padding-top: 36px;

      h3 {
        font-size: 16px;
        margin-bottom: 30px;
        text-transform: uppercase;
      }

      .page-notice-details-other-item {
        color: #2C72EF;
        margin-bottom: 20px;
        font-size: 16px;
        cursor: pointer;
        display: flex;
        justify-content: flex-end;

        div:first-child {
          flex: 1;

          &:hover {
            text-decoration: underline;
          }
        }

        div:last-child {
          color: #8192A5;
        }
      }
    }
  }
}

</style>